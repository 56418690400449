import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { useAnimation, motion, AnimatePresence } from "framer-motion"
import { useInView } from "react-intersection-observer"
import Layout from "../components/layout"

export const query = graphql`
  query CategoryTemplateQuery($id: String!) {
    category: sanityBlogCategory(id: { eq: $id }) {
      title
      description
      image {
        asset {
          altText
          gatsbyImageData
        }
      }
      metaDesc
      metaTitle
      shareGraphic {
        asset {
          altText
          url
        }
      }
    }
    posts: allSanityBlog(
      filter: { blogCategory: { elemMatch: { id: { eq: $id } } } }
      sort: { fields: _createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          id
          title
          excerpt
          slug {
            _key
            _type
            current
          }
          featuredImage {
            _type
            _key
            asset {
              altText
              gatsbyImageData
            }
          }
          blogCategory {
            title
            slug {
              current
            }
          }
        }
      }
    }
  }
`

const BlogCategory = props => {
  const { data = {}, errors } = props
  console.log("data", data)

  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: 0.5,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <Layout pageSeo={data.category}>
      <motion.section
        className="news-post__header relative h-[60vh] overflow-hidden"
        ref={ref}
        animate={controls}
        initial="initial"
        variants={sectionAnim}
      >
        <div className="news-post__header-overlay absolute inset-0 bg-neutral-900/30 z-10"></div>
        {data.category.image.asset && (
          <GatsbyImage
            image={data.category.image.asset.gatsbyImageData}
            alt={data.category.image.asset.altText}
            className="h-full w-full"
          />
        )}
        <div className="news-post__header-content absolute z-20 inset-0 flex items-center justify-center text-white">
          <div className="container flex justify-center items-center">
            {/* <motion.h1 variants={sectionItem} className="page-header uppercase font-bold">{heading}</motion.h1>
            <motion.p variants={sectionItem}>{description}</motion.p> */}
          </div>
        </div>
      </motion.section>

      <section className="-mt-16" data-scroll-section data-scroll>
        <div className="container">
          <div className="news-listing__list grid grid-cols-1 lg:grid-cols-3 gap-4 p-4 rounded-md text-white">
            {data.posts.edges.map((post, index) => (
              <article className="rounded-lg overflow-hidden bg-primary-2 text-white">
                <div className="mb-2">
                  {post.node.featuredImage && (
                    <GatsbyImage
                      image={post.node.featuredImage.asset.gatsbyImageData}
                      alt={post.node.featuredImage.asset.altText}
                      className="object-cover w-full h-full aspect-w-1 aspect-h-1 lg:aspect-w-16 lg:aspect-h-9 z-10"
                    />
                  )}
                  
                </div>
                
                <div className="p-4 ">
                  {/* <div className="flex justify-between">
                    <p>Category</p>
                    <p>Date</p>
                  </div> */}
                  {post.node.blogCategory && (
                    <ul className="flex space-x-1 mb-2">
                    {post.node.blogCategory.map((item, index) => {
                      return (
                        <li
                          className="inline-block px-3 py-1 text-xs font-semibold text-black bg-primary-3 rounded-full"
                        >
                          <Link to={item.slug.current}>
                            {item.title}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                  )}
                  
                  
                  <h2 className="text-xl font-semibold">{post.node.title}</h2>
                  {post.node.excerpt && (
                    <p>{post.node.excerpt}</p>
                  )}
                  
                  <Link
                    to={post.node.slug.current}
                    className="btn-primary mt-4"
                  >
                    Read
                  </Link>
                </div>
              </article>
            ))}
          </div>
          </div>
          </section>
    </Layout>
  )
}

export default BlogCategory

const sectionAnim = {
  // initial: {
  //   y: 20,
  // },
  visible: {
    // y: 0,
    transition: {
      when: "beforeChildren",
      delayChildren: 0.4,
      staggerChildren: 0.1,
      duration: 1,
    },
  },
}

const sectionItem = {
  initial: {
    opacity: 0,
    y: 10,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      when: "beforeChildren",
      delayChildren: 0.4,
      staggerChildren: 0.15,
      duration: 0.3,
    },
  },
}

const sectionImage = {
  initial: {
    scale: 1.1,
  },
  visible: {
    scale: 1,
    transition: {
      when: "beforeChildren",
      delayChildren: 0.4,
      staggerChildren: 0.1,
      duration: 0.6,
    },
  },
}
